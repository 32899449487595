.cover_img_cont{
  width: 100%;
  position: relative 
}
.sliderAlign{
  width:100vw;
  height:100vh;
  object-fit: contain;
  /* object-position: center; */
}

.cover_img{
  width: 100%;
  height: 98vh;
  
}
.cmt{
  margin-top: 7%;
}
.sound_icon{
  position: absolute;
  bottom: 3rem;
  left: 2rem;
  background-color: white;
  border-radius: 50%;
}
@media(max-width:640px){
  .cover_img {
    height: auto;
    margin-top: 4.7rem;
  }
  .sliderAlign{
    width:100vw;
    height:70vh
  }
  .sound_icon{
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    border-radius: 50%;
  }
}
.position-relative{
  position: relative;
  z-index: 100;
}