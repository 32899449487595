.awardimg{
    width: 250px;
    height: 200px;
    border: 3px solid rgba(31, 220, 0, 0.93);
    border-radius: 5px;
    filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25));
}
.acscard{
    width: 300px;
    height: 400px;
    max-height: fit-content;
    border: 3px solid rgba(31, 220, 0, 0.93);
    border-radius: 5px;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
}
.acsimg{
    width: 100%;
    height: 60%;
}
.acsimg1{
    width: 100%;
    height: 100%;
}
.ac{
    text-align: center;
    margin: 1rem;
}
.acdetails{
    text-align: center;
    margin: 1rem;
}
.achv-align{
    width:50vw;
    height:50vh;
    margin-left:2rem;
    margin-top:2rem;
    display: flex;
    justify-content: left;

}

@media (max-width: 640px){
    .achv-align{
        width:90vw;
        height:40vh;
        margin-left:0rem;
    }
    .middlee{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}